import React from "react";
import { Alert, Snackbar, Slide } from "@mui/material";

function Toast({ alert }) {
  return (
    <Snackbar
      open={alert.state}
      autoHideDuration={4000}
      onClose={handleAlertClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      style={{ top: "8px", right: "10px" }}
      TransitionComponent={(props) => (
        <Slide {...props} in={alert.state} direction="right" unmountOnExit />
      )}
    >
      <Alert
        variant="filled"
        severity={alert.type}
        onClose={handleAlertClose}
        style={{ minWidth: "350px" }}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );

  function handleAlertClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
  }
}

export default Toast;
