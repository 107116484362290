import queryString from "query-string";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Spinner } from "react-bootstrap";
import { Checkbox, Typography } from "@mui/material";

import aadhar from "../../assets/aadhar.webp";
import avatar from "../../assets/avatar.jpg";
import useAxios from "../../hooks/useAxios";
import emblem from "../../assets/india_emblem.jpg";
import TextField from "../../components/TextField";
import OTP from "../aadhar components/otp components/OTP";
import LanguageSwitcher from "../toggle language/Togglelanguage";

export default function Home() {
  const axios = useAxios({
    disableAuthToken: true,
  });

  const { t } = useTranslation();

  const id = queryString.parse(window.location.search).id;
  sessionStorage.setItem("okycId", id);

  const [otp, setOtp] = useState(false);
  const [uuid, setUuid] = useState("");
  const [alert, setAlert] = useState({});
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [aadharNumber, setAadharNumber] = useState();
  const [refKey, setRefKey] = useState("");
  const [consentChecked, setConsentChecked] = useState(false);

  return (
    <div className="wrapper">
      {!otp ? (
        <Fragment>
          <div className="hero-heading">
            <Typography variant="h5">{t("verifyWithEkyc")}</Typography>
          </div>

          <div className="hero-heading margin">
            <Typography fontSize={"20px"}>{t("enterAadharNumber")}</Typography>
          </div>
          <div className="aadhar-card">
            <div className="header">
              <div className="emblem-div">
                <img className="emblem" alt="Logo" src={emblem} />
              </div>
              <div className="tri-color">
                <div className="orange"></div>
                <div className="white"></div>
                <div className="green"></div>
              </div>

              <div className="emblem-div">
                <img className="emblem" alt="Logo" src={aadhar} />
              </div>
            </div>

            <div className="middle">
              <div className="user-avatar">
                <img alt="user" src={avatar} className="user-image" />
              </div>

              <div className="user-details">
                <div className="name"></div>
                <div className="address"></div>

                {/* aadhar input */}

                <div className="aadhar-input">
                  {/* <input
                    type="number"
                    pattern="\d*"
                    maxlength="4"
                    onChange={handleChangeAadhar}
                    max={999999999999}
                    value={aadharNumber}
                    className="enter-aadhar aadhar-input-border"
                    placeholder="Enter Aadhar Number"
                  /> */}
                  <TextField
                    inputProps={{ inputMode: "numeric" }}
                    value={aadharNumber}
                    maxLength="14"
                    onChange={handleChangeAadhar}
                    className="enter-aadhar aadhar-input-border"
                    placeholder={t("enterAadharPlaceholder")}
                  />
                </div>
              </div>
            </div>

            <div className="red-line">
              <div className="line" />
            </div>

            {/* aadhar-line */}
            <div className="aadhar-line">
              <div className="aadhar-hindi" />
              <div className="aam-admi" />
            </div>
          </div>

          <div className="captcha">
            <div className="enter-captcha"></div>
          </div>

          {message && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Typography color="red" fontSize={"14px"}>
                {message}
              </Typography>
            </div>
          )}

          <div>
            <Typography>
              <Checkbox
                checked={consentChecked}
                onClick={() => setConsentChecked(!consentChecked)}
              />
              {t("consentText")}
            </Typography>
          </div>

          <div className="submit">
            <Button
              disabled={
                loading ||
                aadharNumber?.length < 14 ||
                /^[0-9]+$/.test(aadharNumber) ||
                !consentChecked
              }
              className="submit-button"
              variant="warning"
              style={{ color: "#fff", borderRadius: "20px" }}
              onClick={handleAadhar}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}{" "}
              {t("submit")}
            </Button>{" "}
          </div>

          <div style={{ textAlign: "center" }} className="margin">
            <Typography variant="body1" color="#989898">
              {t("otpMessage")}
            </Typography>
          </div>

          <LanguageSwitcher></LanguageSwitcher>
        </Fragment>
      ) : (
        <OTP
          refKey={refKey}
          uuid={uuid}
          setOtp={setOtp}
          alert={alert}
          setAlert={setAlert}
        />
      )}
    </div>
  );

  function handleChangeAadhar(e) {
    var val = e.target.value;

    if (!/^[0-9 ]+$/.test(val)) {
      setMessage("Please enter digits only. ");
    } else if (/^[0-9]+$/.test(val) || val === "") {
      setMessage("");
    }

    if (val.length > 14) {
      return;
    }

    if (e.nativeEvent.inputType === "deleteContentBackward") {
      setAadharNumber(val);
    } else {
      setAadharNumber(val.replace(/[^0-9]/g, "").replace(/(.{4})/g, "$1 "));
    }
  }

  function handleAadhar() {
    setLoading(true);

    axios({
      url: "/aadhaar/sendOtp?id=" + id,
      method: "POST",
      data: {
        request: {
          aadhaar: aadharNumber.split(" ").join(""),
        },
      },
    }).then((response) => {
      console.log(response?.body?.data?.response_status?.code);
      if (
        response?.body?.response_code === 101 &&
        response?.body?.data?.response_status?.code === "000"
      ) {
        setAlert({
          state: true,
          message: response?.body.data?.response_status?.message,
          type: "success",
        });
        setUuid(response?.body.data?.response_data.uuid);
        setOtp(true);
        setRefKey(response.refKey);
        setLoading(false);
      }

      //If OTP is generated immediately for the second time ,
      //after the first successful generation

      const responseCodes = [
        "422005",
        "422007",
        "422008",
        "471007",
        "470028",
        "470053",
        "470050",
        "470063",
        "422002",
        "422004",
        "422003",
        "422006",
        "470048",
        "470081",
      ];
      const code = response?.body?.data?.response_status?.code;

      if (responseCodes.includes(code?.toString())) {
        setMessage(t(`${code?.toString()}`));
      } else {
        if (response?.body?.data?.response_status?.code === "422028") {
          setMessage(response.body?.data?.response_status?.message);
        }
        if (response?.body?.data?.response_status?.code === "422005") {
          setMessage(response.data?.response_status?.message);
        }

        //IF WRONG AADHAAR NO IS ENTERED

        if (response?.body?.data?.response_status?.code === "422007") {
          setMessage(response.data.response_status?.message);
        }

        //IF AADHAAR NO IS NOT LINKED WITH MOBILE NO

        if (response?.body?.data?.response_status?.code === "422008") {
          setMessage(response.data.response_status?.message);
        }

        //HASH MISMATCH
        if (response?.body?.data?.response_status?.code === "470028") {
          setMessage(response.data.response_status?.message);
        }

        //HASH PARAMETER NOT PROVIDED
        if (response?.body?.data?.response_status?.code === "470053") {
          setMessage(response.data.response_status?.message);
        }

        //AADHAAR PARAMETER NOT PROVIDEd
        if (response?.body?.data?.response_status?.code === "470050") {
          setMessage(response.data.response_status?.message);
        }

        //FLUCTUATIONS AT UIDAI SIDE
        if (response?.body?.data?.response_status?.code === "471007") {
          setMessage(response.data.response_status?.message);
        }

        //fetching xml from uiadi end
        if (response?.body?.data?.response_status?.code === "333") {
          setMessage(response.data.response_status?.message);
        }

        //CLIENT PARAMETER NOT PROVIDED MEAN
        if (response?.body?.data?.response_status?.code === "470046") {
          setMessage(response.data.response_status?.message);
        }
      }
      if (response?.status === "fail") {
        setMessage(response?.error);
      }

      setLoading(false);
    });
  }
}
