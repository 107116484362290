import React from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import styled from "styled-components";
import PerfectScrollBar from "react-perfect-scrollbar";

const Root = styled.div`
  display: flex;
  height: 100vh;
  text-align: center;

  > div {
    width: 50%;
    flex: 1;
  }
`;

const ScrollBar = styled(PerfectScrollBar)`
  margin: 4px;
  height: calc(100% - 8px) !important;
  display: flex;

  > * {
    flex: 1;
  }
`;

export default function Layout() {
  return (
    <Root>
      <CssBaseline />

      <ScrollBar>
        <Outlet />
      </ScrollBar>
    </Root>
  );
}
