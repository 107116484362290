import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle } from "react-icons/ai";

export default function Success() {
  const { t } = useTranslation();
  return (
    <div className="wrapper">
      <div style={{ textAlign: "center" }}>
        <AiFillCheckCircle fontSize={"80px"} color="#228B22" />
      </div>

      <div style={{ textAlign: "center", marginTop: "25px" }}>
        <Typography variant="h6">{t("thankYou")}</Typography>
        <Typography variant="subtitle1"> {t("ekycSuccess")}</Typography>
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "20px",
          cursor: "pointer",
        }}
      >
        <Typography color={"#0047AB"}>{t("ok")}</Typography>
      </div>
    </div>
  );
}
