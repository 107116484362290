import styled from "styled-components";
import { TextField as MuiTextField } from "@mui/material";

const TextFieldWithCSS = styled(MuiTextField)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
    border: 1px solid #ccc;
    padding: 0px;
  }

  .MuiOutlinedInput-root {
    padding: 7px 10px;
    font-size: 15px;
    border: none;
    border: 1px solid #ccc;
  }

  .MuiOutlinedInput-input {
    padding: 0px;
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
    background-color: #f5f5f5;
    font-size: 18px;
    border-radius: 5px;
  }

  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #f5f5f5;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
`;

export default function TextField({ ...props }) {
  return <TextFieldWithCSS fullWidth variant="outlined" {...props} />;
}
