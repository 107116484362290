import { useRef, useEffect, useCallback } from "react";
import axios from "axios";

import BASE_URL from "../constants/baseURL";

export default function useAxios({
  baseURL = "kotakSdk",
  disableAuthToken = false,
} = {}) {
  const axiosInstance = useRef(axios.create());

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version,

      validateStatus: (status) => status < 500,
    });
  }, [disableAuthToken, baseURL]);

  return useCallback(
    ({
      url,
      method = "GET",
      data,
      params,
      authToken,
      disableRedirect = false,
    }) =>
      axiosInstance
        .current({
          url: url,
          method: method,
          data: data,
          params: params,
          headers: {
            Accept: "application/json",
            ...(!disableAuthToken && {
              Authorization: "Bearer " + authToken || "",
            }),
            "content-type": "application/json",
          },
        })
        .then((response) => {
          return response.data;
        }),
    [disableAuthToken]
  );
}
