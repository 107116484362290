import React from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", label: "English" },
  { code: "hi", label: "हिन्दी" },
  { code: "as", label: "অসমীয়া" },
  { code: "bn", label: "বাংলা" },
  { code: "gu", label: "ગુજરાતી" },
  { code: "kn", label: "ಕನ್ನಡ" },
  { code: "ml", label: "മലയാളം" },
  { code: "mni", label: "মৈতৈলোন্" },
  { code: "mr", label: "मराठी" },
  { code: "or", label: "ଓଡ଼ିଆ" },
  { code: "pa", label: "ਪੰਜਾਬੀ" },
  { code: "ta", label: "தமிழ்" },
  { code: "te", label: "తెలుగు" },
  { code: "ur", label: "اردو" },
];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);

    localStorage.setItem("setLanguage", value);
  };

  return (
    <div className="selectLanguage">
      <ul className="table">
        <li className="languageName">Select Language:</li>

        {languages.map((item, index) => {
          return (
            <li className="languageName" key={index}>
              <a
                href="#"
                onClick={() => changeLanguage(item.code)}
                style={{ color: " rgb(63 140 255)" }}
              >
                {item.label}
              </a>{" "}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
