/*
Contains all the routes pertaining to Auth Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

import pages from "../../constants/pages";
import Home from "../../pages/home components/Home";
import Failure from "../../pages/success components/Failure";
import Success from "../../pages/success components/Success";

const layoutRoutes = {
  signInRoute: {
    ID: pages.home.ID,
    path: pages.home.route,
    element: Home,
  },
  successRoute: {
    ID: pages.success.ID,
    path: pages.success.route,
    element: Success,
  },
  failRoute: {
    ID: pages.fail.ID,
    path: pages.fail.route,
    element: Failure,
  },
};

export default layoutRoutes;
