const pages = {
  //Dashboard Module
  home: {
    ID: "home",
    route: "/",
  },

  success: {
    ID: "success",
    route: "/success",
  },

  fail: {
    ID: "fail",
    route: "/fail",
  },
};

export default pages;
