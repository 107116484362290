import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Button, Spinner } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";

import otp from "../../../assets/otp.png";
import useAxios from "../../../hooks/useAxios";
import pages from "../../../constants/pages";
import Toast from "../../../components/Alert";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./../../toggle language/Togglelanguage";

export default function OTP({ setOtp, uuid, alert, setAlert, refKey }) {
  const axios = useAxios({ disableAuthToken: true });
  const id = sessionStorage.getItem("okycId");

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]);
  const [message, setMessage] = useState("");

  function handleChange(index, e) {
    if (alert.state) {
      setAlert({
        state: false,
      });
    }
    const { maxLength, value, name } = e.target;
    const [_, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 6) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=otp-${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }

    const newotp = [...otpValue];
    newotp[index] = value;
    setOtpValue(newotp);
  }

  function handleOtpSubmit() {
    setLoading(true);
    let otp = "";
    for (let i of otpValue) {
      otp += i;
    }
    axios({
      url: "/aadhaar/verifyAadhaar?id=" + id,
      method: "POST",
      data: {
        request: {
          uuid: uuid,
          otp: otp,
        },
      },
    }).then((response) => {
      const responseCodes = [
        "422005",
        "422007",
        "422008",
        "471007",
        "470028",
        "470053",
        "470050",
        "470063",
        "422002",
        "422004",
        "422003",
        "422006",
        "470048",
        "470081",
      ];
      const code = response.data.response_status.code;

      if (
        response?.response_code === 101 &&
        response?.data?.response_status?.code === "000"
      ) {
        let data = response?.data;
        data.response_data["refKey"] = refKey;
        setLoading(false);
        axios({
          url: "/saveOKYCDetails?id=" + id,
          method: "POST",
          data: data,
        }).then((response) => {});
        navigate(pages.success.route);
      } else if (responseCodes.includes(code?.toString())) {
        setMessage(t(`${code?.toString()}`));
      }

      //IF WRONG OTP IS ENTERED
      else if (response.data.response_status.code === "422002") {
        setMessage(response.data.response_status.message);
        setLoading(false);
      }

      //If wrong OTP is entered for the fourth time
      else if (response.data.response_status.code === "422004") {
        setMessage(response.data.response_status.message);
        setLoading(false);
      }

      //TXN ALREADY COMPLETED- After successful XML fetch, if the same UUID is used , the same / different OTP is used.
      else if (response.data.response_status.code === "470048") {
        setMessage(response.data.response_status.message);
        setLoading(false);
      }

      //If wrong UUID is passed
      else if (response.data.response_status.code === "470081") {
        setMessage(response.data.response_status.message);
        setLoading(false);
      } else if (response.data?.response_status?.code === "333") {
        setMessage(response.data?.response_status?.message);
        navigate(pages.fail.route, {
          state: {
            retry: true,
            message: response.data?.response_status.message,
          },
        });
      } else {
        navigate(pages.fail.route, {
          state: {
            retry: false,
            message: response.data?.response_status.message,
          },
        });

        axios({
          url: "/saveOKYCDetails?id=" + id,
          method: "POST",
          data: response.data,
        }).then((response) => {});
      }
      setLoading(false);
    });
  }

  return (
    <div>
      <Toast alert={alert} setAlert={setAlert} />

      <div className="otp-header">
        <div className="arrow-back">
          <FaArrowLeftLong onClick={() => setOtp(false)} />
        </div>

        <div className="otp-heading">
          <Typography style={{ fontWeight: 600 }} variant="body1">
            {t("enterOTP")}
          </Typography>
        </div>
      </div>

      <div className="otp-image-div">
        <img src={otp} alt="OTP" className="image-otp" />
      </div>

      <div style={{ textAlign: "center", paddingTop: "30px" }}>
        <Typography fontSize={"20px"}>{t("verifyAadhar")}</Typography>
      </div>

      <div className="input-div">
        {otpValue.map((digit, index) => (
          <input
            type="number"
            key={index}
            name={"otp-" + (index + 1)}
            autoComplete="off"
            className="otpInput"
            value={digit}
            onChange={(e) => handleChange(index, e)}
            maxLength={1}
          />
        ))}
      </div>

      {message && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {console.log(message)}
          <Typography color="red" fontSize={"14px"}>
            {message}
          </Typography>
        </div>
      )}

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Typography fontSize={"14px"}>{t("pleaseEnterOTP")}</Typography>
      </div>

      <div className="otp-buttons">
        <Button
          className="otp-button"
          variant="outline-secondary"
          style={{
            borderRadius: "20px",
            marginInlineEnd: "4px",
          }}
          onClick={() => setOtp(false)}
        >
          {t("back")}
        </Button>
        <Button
          className="otp-button"
          variant="warning"
          style={{ color: "#fff", borderRadius: "20px" }}
          onClick={() => handleOtpSubmit()}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}{" "}
          {t("submit")}
        </Button>{" "}
      </div>
      <LanguageSwitcher></LanguageSwitcher>
    </div>
  );
}
