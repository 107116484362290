import { MdCancel } from "react-icons/md";
import { Typography } from "@mui/material";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import pages from "../../constants/pages";

export default function Failure() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const id = sessionStorage.getItem("okycId");

  return (
    <div className="wrapper">
      <div style={{ textAlign: "center" }}>
        <MdCancel fontSize={"80px"} color="red" />
      </div>

      <div style={{ textAlign: "center", marginTop: "25px" }}>
        <Typography variant="h6">{t("sorry")}</Typography>
        <Typography variant="subtitle1">
          {!!state.message ? state.message : t("ekycFailed")}
        </Typography>
      </div>

      {state.retry ? (
        <div style={{ textAlign: "center", marginTop: "25px" }}>
          <Button
            className="otp-button"
            variant="warning"
            style={{ color: "#fff", borderRadius: "20px" }}
            onClick={() => navigate(`${pages.home.route}?id=${id}`)}
          >
            {t("retry")}
          </Button>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            cursor: "pointer",
          }}
          onClick={() => navigate(`${pages.home.route}?id=${id}`)}
        >
          <Typography color={"#0047AB"}>{t("ok")}</Typography>
        </div>
      )}
    </div>
  );
}
