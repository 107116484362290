import React from "react";
import { Alert, Snackbar, Slide } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine-sc";

import "react-perfect-scrollbar/dist/css/styles.css";
import Router from "./routers/Router";
import "./css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <Snackbar
        open={alert.state}
        autoHideDuration={4000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: "8px", right: "10px" }}
        TransitionComponent={(props) => (
          <Slide {...props} in={alert.state} direction="right" unmountOnExit />
        )}
      >
        <Alert
          variant="filled"
          severity={alert.type}
          onClose={handleAlertClose}
          style={{ minWidth: "350px" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Router />
    </StyledEngineProvider>
  );

  function handleAlertClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
  }
}

export default App;
