import React, { useRef } from "react";
import { Routes, Route } from "react-router-dom";

// Layouts
import Layout from "../layouts/Layout";

//Components
import layoutRoutes from "./router components/layoutRoutes";

export default function Router() {
  const allRoutes = useRef([
    {
      ID: "layoutRoutes",
      path: "/",
      layout: Layout,
      children: allChildrenRoutes(layoutRoutes),
    },
  ]);

  return (
    <Routes>
      {allRoutes.current.map(
        ({ ID: layoutID, path, layout: Layout, children }) =>
          layoutID && (
            <Route key={layoutID} path={path} element={<Layout />}>
              {children.map(
                ({ ID: pageID, index, path, element: Element, disableAuth }) =>
                  pageID && (
                    <Route
                      key={pageID}
                      index={!!index}
                      path={path}
                      element={
                        <Element pageID={pageID} disableAuth={!!disableAuth} />
                      }
                    />
                  )
              )}
            </Route>
          )
      )}
    </Routes>
  );
}

function allChildrenRoutes(parentObject) {
  const childrenArray = [];

  for (var key in parentObject) {
    childrenArray.push(parentObject[key]);
  }

  return childrenArray;
}
