import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation files
import translationEN from "./locales/en/translation.json";
import translationHI from "./locales/hi/translation.json";
import translationAS from "./locales/as/translation.json";
import translationBN from "./locales/bn/translation.json";
import translationGU from "./locales/gu/translation.json";
import translationKN from "./locales/kn/translation.json";
import translationML from "./locales/ml/translation.json";
import translationMNI from "./locales/mni/translation.json";
import translationMR from "./locales/mr/translation.json";
import translationOR from "./locales/or/translation.json";
import translationPA from "./locales/pa/translation.json";
import translationTA from "./locales/ta/translation.json";
import translationTE from "./locales/te/translation.json";
import translationUR from "./locales/ur/translation.json";

// The translations
const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
  as: {
    translation: translationAS,
  },
  bn: {
    translation: translationBN,
  },
  gu: {
    translation: translationGU,
  },
  kn: {
    translation: translationKN,
  },
  ml: {
    translation: translationML,
  },
  mni: {
    translation: translationMNI,
  },
  mr: {
    translation: translationMR,
  },
  or: {
    translation: translationOR,
  },
  pa: {
    translation: translationPA,
  },
  ta: {
    translation: translationTA,
  },
  te: {
    translation: translationTE,
  },
  ur: {
    translation: translationUR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("setLanguage") || "en", // default language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
